export const FirebaseConfig = {
	"projectId": "exicubedelivery",
	"appId": "1:195402854114:web:a1ab91c04c908456f7f8bb",
	"databaseURL": "https://exicubedelivery-default-rtdb.firebaseio.com",
	"storageBucket": "exicubedelivery.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCD4RV5pcUFTSgrk0njqim46E6iRdA4v0c",
	"authDomain": "exicubedelivery.firebaseapp.com",
	"messagingSenderId": "195402854114",
	"measurementId": "G-X46D5ZVNWF"
};